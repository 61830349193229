import { computed, ref } from "vue";
import { projectFirestore } from "../../../../firebase/config";
import store from '../../../../store'

const is_sales_print = ref(false)

const user  = ref(computed(()=>{
    return store.state.user
}))

const role = ref(computed(()=>{
    return store.state.role
}))

const check_if_item_exist = async(barcode) => {
    const doc = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('Items').doc(barcode).get()
    if(doc.exists){
        return true
    }
    return false
}
const create_new_sign = async(item) => {
    await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('Items').doc(item.barcode).set(item,{merge:true})
}

const get_all_signs_from_db = async() => {
    const docs = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('Items').get()

    const res = docs.docs.map(doc=>doc.data())
    return res
}

const delete_item_from_db = async(barcode) => {
    await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('Items').doc(barcode).delete()
}

const get_all_departments = async() => {
    const docs = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('departments').get()
    const res = docs.docs.map(doc=>doc.data())
    return res
}
const add_department = async(department_name) => {
    const doc =  projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('departments').doc()
    await doc.set({
        name:department_name,
        uid:doc.id,
    })

    return {
        name:department_name,
        uid:doc.id,
    }
}
const delete_department = async(dep_uid) => {
    await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
    .collection('departments').doc(dep_uid).delete()
}

const pagination_signs = ()=>{

    const pagination = ref({
        pages:0,
        signs:[]
    })
    
    const organise_signs = (amount,signs) => {
        pagination.value.pages = Math.ceil(signs.length / amount)
        pagination.value.signs = []
        for(let i=0; i<signs.length; i=i+amount){
            pagination.value.signs.push(signs.slice(i,i+amount))
        }
    }

    return {
        organise_signs,
        pagination,
    }
}


export{
    create_new_sign,
    check_if_item_exist,
    get_all_signs_from_db,
    delete_item_from_db,
    get_all_departments,
    add_department,
    delete_department,
    pagination_signs,
    is_sales_print,
    user,
    role,
}