<template>
  <div class="shilut-main">
    <div v-show="platform!='mobile'" class="shilut-main-tools">
        <el-button v-if="!is_yedion" @click="push_to_manage('ShilutDealsManage')" type="danger">חזרה לניהול מבצעים</el-button>
        <el-button v-if="is_yedion" @click="push_to_manage('ShilutYedion')" type="warning">חזרה לידעון</el-button>
        <!-- <el-button @click="push_to_manage('ShilutManage')" style="margin-right:5px;" type="success">ניהול</el-button> -->
        <el-button style="margin-right:5px;" v-if="state && state!='OptionsMenu'"  @click="handle_print_signs" type="info">הדפסה</el-button>
        <el-button style="margin-right:5px;" v-if="state && state!='OptionsMenu'"  @click="state='OptionsMenu'" type="danger">חזרה</el-button>
    </div>
    <div v-show="platform!='mobile'" class="shilut-main-content">
        <component 
            :is="state"
            @blank_page="handle_blank_page" 
            :pages="pages"
            :selected_signs="selected_deals"
            @signs_selected="handle_selected_signs"
        />
    </div>
    <OnlyForDesktop v-show="platform=='mobile'" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import router from '../../../router'
import {is_sales_print} from './Scripts/scripts'
import { computed, defineAsyncComponent } from '@vue/runtime-core'
import store from '../../../store'
import {selected_deals,printer_amount,is_catalog_signs} from './components/Deals/Scripts/store'
import {slide_pop_error} from '../../../Methods/Msgs'
import {is_yedion} from './components/Yedion/store'
export default {
    components:{
        BlankPageSales : defineAsyncComponent(() => import('./BlankPageSales.vue')),
        BlankPageSalesTwo: defineAsyncComponent(() => import('./BlankPageSalesTwo.vue')),
        BlankPageItem: defineAsyncComponent(() => import('./BlankPageItem.vue')),
        BlankPageSalesOne: defineAsyncComponent(() => import('./BlankPageSalesOne.vue')),
        BlankPageSalesSix: defineAsyncComponent(() => import('./BlankPageSalesSix.vue')),
        BlankPageSalesEight: defineAsyncComponent(() => import('./BlankPageSalesEight.vue')),
        OptionsMenu: defineAsyncComponent(() => import('./components/OptionsMenu.vue')),
        OnlyForDesktop: defineAsyncComponent(() => import('../../Not_responsible/OnlyForDesktop.vue')),
    },
    setup(){
        const pages = ref(1)
        const state = ref('OptionsMenu')
        // const selected_signs = ref(null)

        const platform = ref(computed(()=>{
            return store.state.platform
        }))

        
        const handle_print_signs = () => {
            try{
                console.log('printer:',printer_amount.value.length);
                console.log('selcted:',selected_deals.value.length);
                // if(!is_catalog_signs.value && printer_amount.value.length != selected_deals.value.length){
                //     throw new Error('אנא המתן לסיום תרגום השילוט!')
                // }
                is_sales_print.value = true
            }catch(err){
                slide_pop_error(err.message)
            }
        }
        const handle_selected_signs = (blank_page_type,signs) => {
            selected_signs.value = signs
            state.value = blank_page_type
        }

        const handle_blank_page = (_state,_pages) => {
            state.value = _state
            pages.value =_pages
        }

        const push_to_manage = (name)=>{
            router.push({name})
        }

        return{
            push_to_manage,
            handle_blank_page,
            handle_selected_signs,
            handle_print_signs,
            // selected_signs,
            selected_deals,
            is_sales_print,
            state,
            platform,
            pages,
            is_yedion
        }
    }
}
</script>

<style scoped>
    .shilut-main{
        width: 100%;
        height: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }
    .shilut-main-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        overflow-x: auto;
    }
    .shilut-main-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }

    ::v-deep(.el-button+.el-button){
        margin: 0;
    }


</style>